import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { renderBlueJaysLabel } from '../libs/shared/helpers';
import FlamesSeatSections from '../constants/FlamesSeatSections';
import JetsSections from '../constants/JetsSections';
import OilersSeatSections from '../constants/OilersSeatSections';
import CanucksSeatSections from '../constants/CanucksSeatSections';
import MapleLeafsSeatSections from '../constants/MapleLeafsSeatSections';
import SenatorsSeatSections from '../constants/SenatorsSeatSections';
import RaptorsSeatSections from '../constants/RaptorsSeatSections';
import BlueJaysSeatSections from '../constants/BlueJaysSeatSections';
import StampedersSeatSections from '../constants/StampedersSeatSections';
import ElksSeatSections from '../constants/ElksSeatSections';
import BlueBombersSeatSections from '../constants/BlueBombersSeatSections';
import LionsSeatSections from '../constants/LionsSeatSections';
import ArgonautsSeatSections from '../constants/ArgonautsSeatSections';
import RedblacksSeatListSections from '../constants/RedblacksSeatListSections';
import RoughridersSeatSections from '../constants/RoughridersSeatSections';
import TigerCatsSeatSections from '../constants/TigerCatsSeatSections';
import WranglersSeatSections from '../constants/WranglersSeatSections';
import MooseSections from '../constants/MooseSections';
import AbbotsfordCanucksSeatSections from '../constants/AbbotsfordCanucksSeatSections';
import WhitecapsSeatSections from '../constants/WhitecapsSeatSections';
import TorontoFcsSeatSections from '../constants/TorontoFcsSeatSections';
import TaylorSwiftTorontoSeatSections from '../constants/TaylorSwiftTorontoSeatSections';
import TaylorSwiftVancouverSeatSections from '../constants/TaylorSwiftVancouverSeatSections';
import WorldJuniorSeatSections from '../constants/WorldJuniorSeatSections';
import BellevilleSenatorsSeatSections from '../constants/BellevilleSenatorsSeatSections';
import MarliesSeatSections from '../constants/MarliesSeatSections';
import HitmenSeatSections from '../constants/HitmenSeatSections';

const teamSections = {
  'calgary-flames': FlamesSeatSections.ZONES,
  'winnipeg-jets': JetsSections.ZONES,
  'edmonton-oilers': OilersSeatSections.ZONES,
  'vancouver-canucks': CanucksSeatSections.ZONES,
  'toronto-maple-leafs': MapleLeafsSeatSections.ZONES,
  'ottawa-senators': SenatorsSeatSections.ZONES,
  'toronto-raptors': RaptorsSeatSections.ZONES,
  'toronto-blue-jays': BlueJaysSeatSections.ZONES,
  'calgary-stampeders': StampedersSeatSections.ZONES,
  'edmonton-elks': ElksSeatSections.ZONES,
  'winnipeg-blue-bombers': BlueBombersSeatSections.ZONES,
  'bc-lions': LionsSeatSections.ZONES,
  'toronto-argonauts': ArgonautsSeatSections.ZONES,
  'ottawa-redblacks': RedblacksSeatListSections.ZONES,
  'saskatchewan-roughriders': RoughridersSeatSections.ZONES,
  'hamilton-tigercats': TigerCatsSeatSections.ZONES,
  'grey-cup': LionsSeatSections.ZONES,
  'calgary-wranglers': WranglersSeatSections.ZONES,
  'manitoba-moose': MooseSections.ZONES,
  'abbotsford-canucks': AbbotsfordCanucksSeatSections.ZONES,
  'vancouver-whitecaps-fc': WhitecapsSeatSections.ZONES,
  'toronto-fc': TorontoFcsSeatSections.ZONES,
  'taylor-swift': {
    'Toronto': TaylorSwiftTorontoSeatSections.ZONES,
    'Vancouver': TaylorSwiftVancouverSeatSections.ZONES,
  },
  'world-junior': WorldJuniorSeatSections.ZONES,
  'belleville-senators': BellevilleSenatorsSeatSections.ZONES,
  'toronto-marlies': MarliesSeatSections.ZONES,
  'calgary-hitmen': HitmenSeatSections.ZONES,
};

class SectionSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.section
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.section !== this.props.section) {
      this.setState({ value: nextProps.section });
    }
  }

  handleSelection = event => {
    this.setState({
      [event.target.name]: event.target.value
    });

    this.props.onSectionSelection(event.target.value);
  };

  render() {
    const { selectedTeam, city } = this.props;
    const sections = selectedTeam === "taylor-swift" ?  get(teamSections, `${selectedTeam}.${city}`, []) : get(teamSections, selectedTeam, []);
    return (
      <FormControl>
        <InputLabel>Section</InputLabel>
        <Select
          disableUnderline={true}
          value={this.state.value}
          onChange={this.handleSelection}
          inputProps={{
            name: 'section',
            id: 'section'
          }}
          placeholder="Section"
        >
          {sections.map((section, index) => (
            <MenuItem value={section.label} key={index}>
              {selectedTeam === "toronto-blue-jays" ? renderBlueJaysLabel(section.label) : section.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }
}

SectionSelect.propsType = {
  selectedTeam: PropTypes.string.isRequired,
  onSectionSelection: PropTypes.func.isRequired,
  section: PropTypes.string.isRequired
};

export default SectionSelect;
