import React, { Component } from "react";
import PropTypes from "prop-types";
import RaptorsRowSelect from "./RaptorsRowSelect";
import BlueJaysRowSelect from "./BlueJaysRowSelect";
import FlamesRowSelect from "./FlamesRowSelect";
import WranglersRowSelect from "./WranglersRowSelect";
import MooseRowSelect from "./MooseRowSelect";
import JetsRowSelect from "./JetsRowSelect";
import CanucksRowSelect from "./CanucksRowSelect";
import MapleLeafsRowSelect from "./MapleLeafsRowSelect";
import SenatorsRowSelect from "./SenatorsRowSelect";
import ElksRowSelect from "./ElksRowSelect";
import ArgonautRowSelect from "./ArgonautsRowSelect";
import TorontoFcsRowSelect from "./TorontoFcsRowSelect";
import BlueBombersRowSelect from "./BlueBombersRowSelect";
import StampedersRowSelect from "./StampedersRowSelect";
import RedblacksRowSelect from "./RedblacksRowSelect";
import LionsRowSelect from "./LionsRowSelect";
import WhitecapsRowSelect from "./WhitecapsRowSelect";
import RoughridersRowSelect from "./RoughridersRowSelect";
import TigercatsRowSelect from "./TigercatsRowSelect";
import OilersRowSelect from "./OilersRowSelect";
import RowSelect from "./RowSelect";
import TaylorSwiftTorontoRowSelect from "./TaylorSwiftTorontoRowSelect";
import TaylorSwiftVancouverRowSelect from "./TaylorSwiftVancouverRowSelect";
import WorldJuniorRowSelect from "./WorldJuniorRowSelect";
import AbbotsfordCanucksRowSelect from "./AbbotsfordCanucksRowSelect";
import UfcEdmontonRowSelect from "./UfcEdmontonRowSelect";
import BellevilleSenatorsRowSelect from "./BellevilleSenatorsRowSelect";
import TorontoMarliesRowSelect from "./TorontoMarliesRowSelect";

class RowSelector extends Component {
  render() {
    const { selectedTeam, section, rowNo, onRowSelection, city } = this.props;
    if (selectedTeam === "calgary-flames" || selectedTeam === "calgary-hitmen") {
      return (
        <FlamesRowSelect
          section={section}
          row={rowNo}
          onRowSelection={onRowSelection}
        />
      );
    } else if (selectedTeam === "winnipeg-jets") {
      return (
        <JetsRowSelect
          section={section}
          row={rowNo}
          onRowSelection={onRowSelection}
        />
      );
    } else if (selectedTeam === "edmonton-oilers") {
      return (
        <OilersRowSelect
          section={section}
          row={rowNo}
          onRowSelection={onRowSelection}
        />
      );
    } else if (selectedTeam === "vancouver-canucks") {
      return (
        <CanucksRowSelect
          section={section}
          row={rowNo}
          onRowSelection={onRowSelection}
        />
      );
    } else if (selectedTeam === "calgary-flames") {
      return (
        <FlamesRowSelect
          section={section}
          row={rowNo}
          onRowSelection={onRowSelection}
        />
      );
    }
    else if (selectedTeam === "toronto-maple-leafs") {
      return (
        <MapleLeafsRowSelect
          section={section}
          row={rowNo}
          onRowSelection={onRowSelection}
        />
      );
    } else if (selectedTeam === "toronto-raptors") {
      return (
        <RaptorsRowSelect
          section={section}
          row={rowNo}
          onRowSelection={onRowSelection}
        />
      );
    } else if (selectedTeam === "toronto-blue-jays") {
      return (
        <BlueJaysRowSelect
          section={section}
          row={rowNo}
          onRowSelection={onRowSelection}
        />
      );
    } else if (selectedTeam === "edmonton-elks") {
      return (
        <ElksRowSelect
          section={section}
          row={rowNo}
          onRowSelection={onRowSelection}
        />
      );
    } else if (selectedTeam === "vancouver-canucks") {
      return (
        <CanucksRowSelect
          section={section}
          row={rowNo}
          onRowSelection={onRowSelection}
        />
      );
    } else if (selectedTeam === "ottawa-senators") {
      return (
        <SenatorsRowSelect
          section={section}
          row={rowNo}
          onRowSelection={onRowSelection}
        />
      );
    } else if (selectedTeam === "calgary-flames") {
      return (
        <FlamesRowSelect
          section={section}
          row={rowNo}
          onRowSelection={onRowSelection}
        />
      );
    } else if (selectedTeam === "bc-lions") {
      return (
        <LionsRowSelect
          section={section}
          row={rowNo}
          onRowSelection={onRowSelection}
        />
      );
    } else if (selectedTeam === "toronto-argonauts") {
      return (
        <ArgonautRowSelect
          section={section}
          row={rowNo}
          onRowSelection={onRowSelection}
        />
      );
    } else if (selectedTeam === "winnipeg-blue-bombers") {
      return (
        <BlueBombersRowSelect
          section={section}
          row={rowNo}
          onRowSelection={onRowSelection}
        />
      );
    } else if (selectedTeam === "calgary-stampeders") {
      return (
        <StampedersRowSelect
          section={section}
          row={rowNo}
          onRowSelection={onRowSelection}
        />
      );
    } else if (selectedTeam === "ottawa-redblacks") {
      return (
        <RedblacksRowSelect
          section={section}
          row={rowNo}
          onRowSelection={onRowSelection}
        />
      );
    } else if (selectedTeam === "saskatchewan-roughriders") {
      return (
        <RoughridersRowSelect
          section={section}
          row={rowNo}
          onRowSelection={onRowSelection}
        />
      );
    } else if (selectedTeam === "hamilton-tigercats") {
      return (
        <TigercatsRowSelect
          section={section}
          row={rowNo}
          onRowSelection={onRowSelection}
        />
      );
    } else if (selectedTeam === "grey-cup") {
      return (
        <LionsRowSelect
          section={section}
          row={rowNo}
          onRowSelection={onRowSelection}
        />
      );
    } else if (selectedTeam === "calgary-wranglers") {
      return (
        <WranglersRowSelect
          section={section}
          row={rowNo}
          onRowSelection={onRowSelection}
        />
      );
    } else if (selectedTeam === "manitoba-moose") {
      return (
        <MooseRowSelect
          section={section}
          row={rowNo}
          onRowSelection={onRowSelection}
        />
      );
    } else if (selectedTeam === "vancouver-whitecaps-fc") {
      return (
        <WhitecapsRowSelect
          section={section}
          row={rowNo}
          onRowSelection={onRowSelection}
        />
      );
    } else if (selectedTeam === "toronto-fc") {
      return (
        <TorontoFcsRowSelect
          section={section}
          row={rowNo}
          onRowSelection={onRowSelection}
        />
      );
    } else if (selectedTeam === "taylor-swift") {
      if (city === "Toronto") {
        return (
          <TaylorSwiftTorontoRowSelect
            section={section}
            row={rowNo}
            onRowSelection={onRowSelection}
          />
        );
      } else if (city === "Vancouver") {
        return (
          <TaylorSwiftVancouverRowSelect
            section={section}
            row={rowNo}
            onRowSelection={onRowSelection}
          />
        );
      } else {
        return (
          <RowSelect
            section={section}
            row={rowNo}
            onRowSelection={onRowSelection}
          />
        );
      }
    } else if (selectedTeam === "world-junior") {
      return (
        <WorldJuniorRowSelect
          section={section}
          row={rowNo}
          onRowSelection={onRowSelection}
        />
      );
    } else if (selectedTeam === "world-junior") {
      return (
        <WorldJuniorRowSelect
          section={section}
          row={rowNo}
          onRowSelection={onRowSelection}
        />
      );
    } else if (selectedTeam === "abbotsford-canucks") {
      return (
        <AbbotsfordCanucksRowSelect
          section={section}
          row={rowNo}
          onRowSelection={onRowSelection}
        />
      );
    } else if (selectedTeam === "ufc-fight-night") {
      return (
        <UfcEdmontonRowSelect
          section={section}
          row={rowNo}
          onRowSelection={onRowSelection}
        />
      );
    } else if (selectedTeam === "belleville-senators") {
      return (
        <BellevilleSenatorsRowSelect
          section={section}
          row={rowNo}
          onRowSelection={onRowSelection}
        />
      );
    } else if (selectedTeam === "toronto-marlies") {
      return (
        <TorontoMarliesRowSelect
          section={section}
          row={rowNo}
          onRowSelection={onRowSelection}
        />
      );
    } else {
      return (
        <RowSelect
          section={section}
          row={rowNo}
          onRowSelection={onRowSelection}
        />
      );
    }
  }
}

RowSelector.propsType = {
  selectedTeam: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired,
  rowNo: PropTypes.string.isRequired,
  onRowSelection: PropTypes.func.isRequired,
};

export default RowSelector;
